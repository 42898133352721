<template>
    <div>
        <p class="text text-margin">
            {{ $t('faq.flexIptv.step5.insertDetails') }}
        </p>

        <ul class="step-list">
            <li v-for="{ title, path } in linksList" :key="path" class="step-item">
                <p class="title">{{ $t(title) }}</p>
                <p class="link">{{ generalLink + path }}</p>
            </li>
        </ul>

        <p class="text">
            {{ $t('faq.flexIptv.step5.inserRequiredEpg') }}
        </p>

        <div class="screenshot">
            <img src="../../assets/flexIptv/screen-5.png" :alt="$t('faq.flexIptv.step5.inserRequiredEpg')" :width="317" :height="276" class='step-image'>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['brandConfig']),
        generalLink() {
            return `${this.brandConfig.tvLink}/api/list/USERNAME/PASSWORD/m3u8`
        },
    },
    data() {
        return {
            linksList: [
                {
                    title: "faq.flexIptv.step5.titleLink1",
                    path: "/livetv",
                },
                {
                    title: "faq.flexIptv.step5.titleLink2",
                    path: "/movies",
                },
                {
                    title: "faq.flexIptv.step5.titleLink3",
                    path: "/tvshows/1",
                },
            ],
        }
    }

}
</script>

<style scoped>
@import "../../../../components/styles/guides-default-styles.css";
.step-list {
    list-style: none;
    margin: 0 0 32px 0;
    padding: 0;
}

.step-item:not(:last-child) {
    margin-bottom: 20px;
}

.title {
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 3px;
}
</style>
